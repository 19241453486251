/* You can add global styles to this file, and also import other style files */
* {
    font-family: "Verdana", Arial, Helvetica, sans-serif;
    color: #fff;
}
html, body {
    margin:0;
    padding:0;
}

html {
    background-image: url("./assets/fondo.jpg");
    background-size: cover;
    background-attachment: fixed;
}

body {
    margin:0;
    height: 100vh;
    background: rgb(255 255 255 / 84%);
    overflow: hidden;
}
html, body, p, * {
    font-family: Arial, Helvetica, sans-serif;
}